import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import {seo} from 'src/config';

export const withSEO = (hocProps = {}) => Component => {
  class HOC extends React.Component {

    getPageTitle = () => {
      if(typeof window !== 'undefined') {
        if(window.location.pathname === '/') {
          return 'Home - ' + seo.title;
        }
        return window.location.pathname
                .split('/')[1]
                .split('-')
                .map(x => x.charAt(0)
                .toUpperCase() + x.substring(1))
                .join(' ') + ' - ' + seo.title;
      }
      return seo.title;
    };

    getPageUrl = () => {
      if(typeof window !== 'undefined') {
        return seo.url + (window.location.pathname === '/' ? '' : window.location.pathname)
      }
      return seo.url;
    };

    render() {
      const description = hocProps.description || this.props.description;
      const image = hocProps.image || this.props.image;
      const keywords = hocProps.keywords || this.props.keywords;
      const title = hocProps.title || this.getPageTitle();
      const url = this.getPageUrl();

      return (
        <React.Fragment>
          <Helmet>
            <title>{title}</title>
            <meta name='description' content={description}/>
            <meta name='image' content={image}/>
            <meta name='keywords' content={keywords}/>
            <meta name='og:description' content={description}/>
            <meta name='og:image' content={image}/>
            <meta name='og:title' content={title}/>
            <meta name='og:type' content='website'/>
            <meta name='og:url' content={url}/>
            <script crossOrigin='anonymous' src='https://polyfill.io/v3/polyfill.min.js'></script>
          </Helmet>
          <Component {...this.props}/>
        </React.Fragment>
      );
    }
  }

  HOC.defaultProps = {
    description: seo.description,
    image: seo.image,
    keywords: seo.keywords
  };

  HOC.propTypes = {
    description: PropTypes.string,
    image: PropTypes.string,
    keywords: PropTypes.string,
    title: PropTypes.string
  };

  return HOC;
};

withSEO.propTypes = {
  Component: PropTypes.element.isRequired
};
